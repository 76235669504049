/*
  财务管理 - 账单导出
*/
<template>
  <a-card>
    <a-card>
      <!-- 首要搜索框 -->
      <h3>导出账单信息</h3>
      <div style="margin-top: 24px">
        <a-row :gutter="0" style="line-height: 3">
          <div class="table-operator">
            <a-form :form="billForm" layout="inline">
              <a-row :gutter="5" style="line-height: 3.5">
                <!-- 选择城市 -->
                <a-col :md="12" :sm="24">
                  <a-form-item label="选择城市:">
                    <a-select
                      mode="multiple"
                      style="width: 200px; margin-left: 0.5em"
                      :disabled="billCitySelectAll"
                      placeholder="请选择"
                      v-decorator="['cityIds']"
                    >
                      <a-select-option
                        v-for="item in userCities"
                        :key="item.id"
                        >{{ item.name }}</a-select-option
                      >
                    </a-select>
                    <a-checkbox
                      v-model="billCitySelectAll"
                      class="checkbox-margin"
                      >全选</a-checkbox
                    >
                  </a-form-item>
                </a-col>
                <!-- 账单类型 -->
                <a-col :md="12" :sm="24">
                  <a-form-item label="账单类型:">
                    <a-select
                      mode="multiple"
                      style="width: 200px; margin-left: 0.5em"
                      :disabled="billTypeSelectAll"
                      placeholder="请选择"
                      v-decorator="['billTypes']"
                    >
                      <a-select-option
                        v-for="item in billTypeList"
                        :key="item.id"
                        >{{ item.name }}</a-select-option
                      >
                    </a-select>
                    <a-checkbox
                      v-model="billTypeSelectAll"
                      class="checkbox-margin"
                      >全选</a-checkbox
                    >
                  </a-form-item>
                </a-col>
                <!-- 账单锁定日 -->
                <a-col :md="12" :sm="24">
                  <a-form-item label="账单锁定日">
                    <a-range-picker
                      format="YYYY-MM-DD"
                      v-decorator="[
                        'billCycle',
                        {
                          rules: [{ required: true, message: '请选择日期' }],
                          initialValue: initialDateParams,
                        },
                      ]"
                    />
                  </a-form-item>
                </a-col>
              </a-row>
            </a-form>
          </div>
        </a-row>
        <a-row :gutter="0" style="line-height: 3">
          <a-col :md="24" :sm="24" class="fr">
            <a-button
              class="button"
              :loading="billExportMainLoading"
              type="primary"
              @click="exportExcel"
              >导出账单汇总</a-button
            >
            <a-button
              class="button"
              type="primary"
              :loading="billExportDetailLoading"
              @click="exportDetailExcel"
              >导出账单明细</a-button
            >
          </a-col>
        </a-row>
      </div>
    </a-card>
    <a-card style="margin-top: 24px">
      <!-- 首要搜索框 -->
      <h3>导出支付信息</h3>
      <div style="margin-top: 24px">
        <a-row :gutter="0" style="line-height: 3">
          <div class="table-operator">
            <a-form :form="payForm" layout="inline">
              <a-row :gutter="5" style="line-height: 3.5">
                <!-- 选择城市 -->
                <a-col :md="12" :sm="24">
                  <a-form-item label="选择城市:">
                    <a-select
                      mode="multiple"
                      style="width: 200px; margin-left: 0.5em"
                      placeholder="请选择"
                      :disabled="payCitySelectAll"
                      v-decorator="['cityIds']"
                    >
                      <a-select-option
                        v-for="item in userCities"
                        :key="item.id"
                        >{{ item.name }}</a-select-option
                      >
                    </a-select>
                    <a-checkbox
                      v-model="payCitySelectAll"
                      class="checkbox-margin"
                      >全选</a-checkbox
                    >
                  </a-form-item>
                </a-col>
                <!-- 账单类型 -->
                <a-col :md="12" :sm="24">
                  <a-form-item label="账单类型:">
                    <a-select
                      mode="multiple"
                      style="width: 200px; margin-left: 0.5em"
                      :disabled="payBillTypeSelectAll"
                      placeholder="请选择"
                      v-decorator="['billTypes']"
                    >
                      <a-select-option
                        v-for="item in billTypeList"
                        :key="item.id"
                        >{{ item.name }}</a-select-option
                      >
                    </a-select>
                    <a-checkbox
                      v-model="payBillTypeSelectAll"
                      class="checkbox-margin"
                      >全选</a-checkbox
                    >
                  </a-form-item>
                </a-col>
                <!-- 账单锁定日 -->
                <a-col :md="12" :sm="24">
                  <a-form-item label="账单锁定日">
                    <a-range-picker
                      format="YYYY-MM-DD"
                      v-decorator="[
                        'billCycle',
                        {
                          initialValue: initialDateParams,
                        },
                      ]"
                    />
                  </a-form-item>
                </a-col>
                <a-col :md="12" :sm="24">
                  <a-form-item label="支付日">
                    <a-range-picker
                      format="YYYY-MM-DD"
                      v-decorator="[
                        'payCycle',
                        {
                          initialValue: initialDateParams,
                        },
                      ]"
                    />
                  </a-form-item>
                </a-col>
              </a-row>
            </a-form>
          </div>
        </a-row>
        <a-row :gutter="0" style="line-height: 3">
          <a-col :md="24" :sm="24" class="fr">
            <a-button
              class="button"
              type="primary"
              :loading="payExportLoading"
              @click="exportPayExcel"
              >导出</a-button
            >
          </a-col>
        </a-row>
      </div>
    </a-card>
  </a-card>
</template>
<script>
import moment from "moment";
import fileDownload from "js-file-download";
import { mapActions, mapGetters } from "vuex";
import { GET_USER_CITIES } from "@/store/modules/actions.type";

export default {
  name: "finExportExcel",
  data() {
    return {
      // 导出账单汇总按钮的loading
      billExportMainLoading: false,
      // 导出账单明细按钮的loading
      billExportDetailLoading: false,
      // 支付信息 - 导出按钮的loading
      payExportLoading: false,
      //账单类型
      billTypeList: [
        {
          id: 1,
          name: "首期款账单",
        },
        {
          id: 2,
          name: "改造费用账单",
        },
        {
          id: 3,
          name: "退租结算账单",
        },
        {
          id: 4,
          name: "基础服务费账单",
        },
        {
          id: 5,
          name: "经营费用账单",
        },
        { id: 6, name: "工程改造账单" },
        { id: 99, name: "人工调整" },
      ],
      billForm: this.$form.createForm(this),
      payForm: this.$form.createForm(this),
      initialDateParams: [moment(`${moment().year()}-01-01`), moment()],
      billCitySelectAll: true,
      billTypeSelectAll: true,
      payCitySelectAll: true,
      payBillTypeSelectAll: true,
    };
  },
  methods: {
    ...mapActions("cities", [GET_USER_CITIES]),
    moment,
    // 空数据验证
    isCanGetExcel(data) {
      let key = true;
      for (let k in data) {
        let c = typeof data[k] == "number" && !data[k];
        if (!data[k] && !c) {
          key = false;
          break;
        }
      }
      return key;
    },
    validateBillCheckBox(values) {
      if (
        !this.billCitySelectAll &&
        (!values.cityIds || values.cityIds.length === 0)
      ) {
        this.$message.error("请选择城市！");
        return false;
      }
      if (
        !this.billTypeSelectAll &&
        (!values.billTypes || values.billTypes.length === 0)
      ) {
        this.$message.error("请选择账单类型！");
        return false;
      }
      return true;
    },
    // 点击下载
    exportExcel() {
      this.billForm.validateFields((errors, values) => {
        if (!this.validateBillCheckBox(values)) {
          return;
        }
        if (!errors) {
          let data = {
            adjustDateBegin: values.billCycle[0]
              ? values.billCycle[0].format("YYYY-MM-DD")
              : null,
            adjustDateEnd: values.billCycle[1]
              ? values.billCycle[1].format("YYYY-MM-DD")
              : null,
            cityIds: this.billCitySelectAll ? [0] : values.cityIds,
            billTypes: this.billTypeSelectAll ? [0] : values.billTypes,
          };
          this.billExportMainLoading = true;
          // this.downloadTemplate(data, "bill/exportFinalBill", "账单汇总记录");
          this.$http.post("export/file/exportBills", data).then((res) => {
            if (res.status === 200) {
              this.$message.success("提交导出任务成功，请稍后到导出文件下载！");
            } else {
              this.$message.error("导出失败！" + res.data);
            }
          });
          this.billExportMainLoading = false;
        }
      });
    },
    exportDetailExcel() {
      this.billForm.validateFields((errors, values) => {
        if (!this.validateBillCheckBox(values)) {
          return;
        }
        if (!errors) {
          let data = {
            adjustDateBegin: values.billCycle[0]
              ? values.billCycle[0].format("YYYY-MM-DD")
              : null,
            adjustDateEnd: values.billCycle[1]
              ? values.billCycle[1].format("YYYY-MM-DD")
              : null,
            cityIds: this.billCitySelectAll ? [0] : values.cityIds,
            billTypes: this.billTypeSelectAll ? [0] : values.billTypes,
          };

          this.billExportDetailLoading = true;
          // 数据验证
          if (this.isCanGetExcel(data)) {
            // this.downloadTemplate(
            //   data,
            //   "bill/exportFinalBillDetail",
            //   "账单明细记录"
            // );
            this.$http.post("export/file/exportBillDetails", data).then((res) => {
              if (res.status === 200) {
                this.$message.success("提交导出任务成功，请稍后到导出文件下载");
              } else {
                this.$message.error("导出失败！" + res.data);
              }
            });
          } else {
            this.$message.error("导出条件有误！");
          }
          this.billExportDetailLoading = false;
        }
      });
    },
    validatePayCheckBox(values) {
      if (
        !this.payCitySelectAll &&
        (!values.cityIds || values.cityIds.length === 0)
      ) {
        this.$message.error("请选择城市！");
        return false;
      }
      if (
        !this.payBillTypeSelectAll &&
        (!values.billTypes || values.billTypes.length === 0)
      ) {
        this.$message.error("请选择账单类型！");
        return false;
      }
      return true;
    },
    exportPayExcel() {
      this.payForm.validateFields((errors, values) => {
        if (!errors) {
          if (!this.validatePayCheckBox(values)) {
            return;
          }
          let billNull = !values.billCycle[0];
          let payNull = !values.payCycle[0];
          if (billNull && payNull) {
            this.$message.error("账单锁定日与支付日必选择其一！");
            return;
          }
          let dateParams = {};
          if (!billNull) {
            dateParams.adjustDateBegin = values.billCycle[0].format("YYYY-MM-DD");
            dateParams.adjustDateEnd = values.billCycle[1].format("YYYY-MM-DD");
          }
          if (!payNull) {
            dateParams.payTimeBegin = values.payCycle[0].format("YYYY-MM-DD");
            dateParams.payTimeEnd = values.payCycle[1].format("YYYY-MM-DD");
          }
          let data = {
            cityIds: this.payCitySelectAll ? [0] : values.cityIds,
            billTypes: this.payBillTypeSelectAll ? [0] : values.billTypes,
            ...dateParams,
          };
          this.payExportLoading = true;
          // 数据验证
          if (this.isCanGetExcel(data)) {
            // this.downloadTemplate(data, "/bill/exportPayBill", "支付明细记录");
            this.$http.post("export/file/exportBillPayDetails", data).then((res) => {
              if (res.status === 200) {
                this.$message.success("提交导出任务成功，请稍后到导出文件下载");
              } else {
                this.$message.error("导出失败！" + res.data);
              }
            });
          } else {
            this.$message.error("导出条件有误！");
          }
          this.payExportLoading = false;
        }
      });
    },
    // 导出函数
    downloadTemplate(data, url, name) {
      this.$http.post(url, data, { responseType: "blob" }).then((res) => {
        if (res.status === 200) {
          this.$message.success("下载成功！");
          const fileName = name + moment().format('YYYYMMDD') + ".xls";
          fileDownload(res.data, fileName);
        } else {
          this.$message.error("下载失败！");
        }
        this.billExportMainLoading = false;
        this.billExportDetailLoading = false;
        this.payExportLoading = false;
      });
    },
  },
  created() {
    // 初始化城市列表
    this.getUserCities();
  },
  computed: {
    ...mapGetters("cities", ["userCities"]),
  },
};
</script>
<style lang="less" scoped>
.fr {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.nob {
  border-radius: 0 !important;
}
.pad10 {
  box-sizing: border-box;
  padding: 10px 0 10px 10px;
  h3 {
    margin-bottom: 10px;
  }
}
.upfile {
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 3;
  > a {
    color: rgb(85, 163, 200);
    text-decoration: underline;
  }
  p {
    color: rgb(85, 163, 200);
    margin-left: 1.2em;
    margin-bottom: 0;
    line-height: 3;
    text-decoration: underline;
  }
  span {
    margin-left: 1.5em;
    cursor: pointer;
    font-size: 1.5em;
    line-height: 1.5em;
  }
}
.spAl {
  font-size: 0.8em;
  margin-left: 8px;
  color: #aaa;
}
.button {
  width: 120px;
  margin-left: 10px;
}
.checkbox-margin {
  margin-left: 6px;
}
</style>
